import React from 'react';
import payQrCode from '../../dist/images/pay-qr-code.webp';

const SupportPage = () => {
  return (
    <React.Fragment>
      <form className='app-form'>
        <div className='title center-items'>
          <span> Support </span>
        </div>
        <div className='data-all'>
          <div className='data-support'>
            <div className='query'>
              <div className='support-title'>Query</div>
              <div className='body'>
                <p>Having a question? Want to give us a feedback?</p>
                <p id='share-email'>
                  <i class='fas fa-angle-double-right'></i>
                  Write us at{' '}
                  <a href='mailto:prakarsh.ptech@gmail.com'>
                    <b>prakarsh.ptech@gmail.com</b>
                  </a>
                </p>
              </div>
            </div>
            <div className='support-us'>
              <div className='support-title'>Support Us</div>
              <div className='body'>
                <p>
                  We are a growing team, and we need your help to make this app
                  better for you guys. We have a lot in our mind and we’ll soon
                  be pushing updates to this app. We won't let you down.{' '}
                </p>
                <p>To support us, please contribute to this account.</p>
                <span className='full-center-items'>
                  <img src={payQrCode} alt='' className='payment-qr-code' />
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default SupportPage;
