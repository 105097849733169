import React from 'react';
import { logout } from '../../actions/auth';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logoImg from '../../dist/images/logo-new-new.webp';

const Navbar = ({ auth: { isAuthenticated, loading, user }, logout }) => {
  return (
    <div className='nav-bar'>
      <div className='logo'>
        <a href={window.location.origin}>
          <div
            className='logo-area'
            style={{ backgroundImage: `url(${logoImg})` }}
          ></div>
        </a>
      </div>
      <div className='nav-btn'>
        <ul>
          <li>
            <div className='one-btn'>
              <NavLink to='/dashboard/social' activeClassName='active-btn'>
                <div className='icon center-items'>
                  <span
                    className='iconify'
                    data-icon='bi:people'
                    data-inline='false'
                  ></span>
                </div>
                <div className='btn-name center-items'>Social Links</div>
              </NavLink>
            </div>
          </li>
          <li>
            <div className='one-btn'>
              <NavLink
                to='/dashboard/custom-links'
                activeClassName='active-btn'
              >
                <div className='icon center-items'>
                  <span
                    className='iconify'
                    data-icon='akar-icons:link-chain'
                    data-inline='false'
                  ></span>
                </div>
                <div className='btn-name center-items'>Custom Links</div>
              </NavLink>
            </div>
          </li>
          <li>
            <div className='one-btn'>
              <NavLink to='/dashboard/appearance' activeClassName='active-btn'>
                <div className='icon center-items'>
                  <span
                    className='iconify'
                    data-icon='dashicons:admin-appearance'
                    data-inline='false'
                  ></span>
                </div>
                <div className='btn-name center-items'>Appearance</div>
              </NavLink>
            </div>
          </li>
          <li>
            <div className='one-btn'>
              <NavLink to='/dashboard/settings' activeClassName='active-btn'>
                <div className='icon center-items'>
                  <span
                    className='iconify'
                    data-icon='carbon:settings'
                    data-inline='false'
                  ></span>
                </div>
                <div className='btn-name center-items'>Settings</div>
              </NavLink>
            </div>
          </li>
          <li>
            <div className='one-btn'>
              <NavLink to='/dashboard/support' activeClassName='active-btn'>
                <div className='icon center-items'>
                  <span
                    className='iconify'
                    data-icon='bx:bx-donate-heart'
                    data-inline='false'
                  ></span>
                </div>
                <div className='btn-name center-items'>Support</div>
              </NavLink>
            </div>
          </li>
        </ul>
      </div>
      {/* Profile logo */}
      {/* <div className='profile-small center-items'>
        <div className='dark-bg' id='profile-card'>
          <img
            id='profile-photo'
            src='https://media-exp3.licdn.com/dms/image/C5603AQEPVyNlEWHRVg/profile-displayphoto-shrink_200_200/0/1607192847662?e=1628121600&v=beta&t=oGRWEIduIkP7dFTcIeoQNvvItT7JE-ZW80f2njoi7_c'
          ></img>
          <div className='text-wrapper'>
            <div id='name'>{user && user.name}</div>
            <Link onClick={logout} to='/login'>
              <div className='contact-wrapper hide-sm'>Logout</div>
            </Link>
          </div>
        </div>
      </div> */}
    </div>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(Navbar);
