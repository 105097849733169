import React, { useState, useEffect, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile, getCurrentProfile } from '../../actions/profile';
import { logout } from '../../actions/auth';
import AddCustomLink from './AddCustomLink';
import DispCustomLink from './DispCustomLink';

const EditCustomLinks = ({
  profile: { profile, loading },
  // createProfile,
  getCurrentProfile,
  // history,
  // logout,
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [loading]);

  return (
    <Fragment>
      <form className='app-form'>
        <div className='title center-items'>
          <span> Custom Links </span>
        </div>
        <div className='data-all'>
          <div className='data-custom-links'>
            <AddCustomLink />
            <DispCustomLink customLinkArray={profile.customLinks} />
          </div>
        </div>
      </form>
    </Fragment>
  );
};

EditCustomLinks.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, {
  createProfile,
  getCurrentProfile,
  logout,
})(withRouter(EditCustomLinks));
