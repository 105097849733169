import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Alert = ({ alerts }) => {
  var iframe = document.getElementById('mobile-disp-iframe');
  const reloadIframe = () => {
    if (iframe) {
      iframe.src = iframe.src;
    }
    return <> </>;
  };
  if (alerts !== null && alerts.length > 0) {
    return (
      <section class='alerts'>
        {alerts.map((alert) => (
          <div key={alert.id} className={`alert alert-${alert.alertType}`}>
            {alert.msg}
            {alert.alertType === 'success' ? <>{reloadIframe()}</> : <></>}
          </div>
        ))}
      </section>
    );
  } else return <></>;
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
