const appearanceDatas = [
  {
    match: 'appearance-id-1',
    userBlock: {
      backgroundImage: 'linear-gradient(180deg, #fc30b7, #fe6f4d)',
      color: 'white',
    },
    socialLinkBg: {
      backgroundColor: '#ffffff42',
    },
    customBtn: {
      color: 'white',
    },
    customBtnHover: {
      backgroundColor: 'white',
      color: '#FD5180',
      borderColor: 'white',
    },
    logoImage: 'logoLightMono',
  },
  {
    match: 'appearance-id-2',
    userBlock: {
      backgroundImage: 'linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%)',
      color: 'white',
    },
    socialLinkBg: {
      backgroundColor: '#ffffff42',
    },
    customBtn: {
      color: 'white',
    },
    customBtnHover: {
      backgroundColor: 'white',
      color: '#BD5DA5',
      borderColor: 'white',
    },
    logoImage: 'logoLight',
  },
  {
    match: 'appearance-id-3',
    userBlock: {
      backgroundImage: 'linear-gradient(180deg, #0078FF, #002E6E)',
      color: 'white',
    },
    socialLinkBg: {
      backgroundColor: '#ffffff42',
    },
    customBtn: {
      color: 'white',
    },
    customBtnHover: {
      backgroundColor: 'white',
      color: '#0053B6',
      borderColor: 'white',
    },
    logoImage: 'logoDark',
  },

  {
    match: 'appearance-id-4',
    userBlock: {
      backgroundImage: 'linear-gradient(180deg, pink, lightyellow)',
      color: 'black',
    },
    socialLinkBg: {
      backgroundColor: '#5f5f5f42',
    },
    customBtn: {
      color: 'black',
    },
    customBtnHover: {
      backgroundColor: 'black',
      color: '#FFE1D6',
      borderColor: 'black',
    },
    logoImage: 'logoLight',
  },
  {
    match: 'appearance-id-5',
    userBlock: {
      backgroundImage: 'linear-gradient(180deg, #a8ff78, #78ffd6)',
      color: 'black',
    },
    socialLinkBg: {
      backgroundColor: '#ffffff42',
    },
    customBtn: {
      color: 'black',
    },
    customBtnHover: {
      backgroundColor: 'black',
      color: '#91FFA6',
      borderColor: 'black',
    },
    logoImage: 'logoLight',
  },
  {
    match: 'appearance-id-6',
    userBlock: {
      backgroundImage:
        'url("https://cdn.wallpapersafari.com/89/72/TmL75o.jpg")',

      color: 'white',
    },
    socialLinkBg: {
      backgroundColor: '#ffffff42',
    },
    customBtn: {
      color: 'white',
    },
    customBtnHover: {
      backgroundColor: 'white',
      color: 'black',
      borderColor: 'white',
    },
    logoImage: 'logoDark',
  },
  {
    match: 'appearance-id-7',
    userBlock: {
      backgroundImage:
        'url("https://64.media.tumblr.com/6cb0ae44278156aa660d95d55df340de/tumblr_nz14o7t0Z61skcd7fo1_500.gifv")',
      color: 'white',
    },
    socialLinkBg: {
      backgroundColor: '#ffffff42',
    },
    customBtn: {
      color: 'white',
    },
    customBtnHover: {
      backgroundColor: 'white',
      color: 'black',
      borderColor: 'white',
    },
    logoImage: 'logoLightMono',
  },
  {
    match: 'appearance-id-8',
    userBlock: {
      backgroundImage:
        'url("https://media0.giphy.com/media/hFhygTRHt4jvGQo52q/giphy.gif?cid=ecf05e47sa6bwyj6pl4hz593br1tl08mrmn8cnqrrnvbzjwf&rid=giphy.gif&ct=g")',
      color: 'white',
    },
    socialLinkBg: {
      backgroundColor: '#ffffff42',
    },
    customBtn: {
      color: 'white',
    },
    customBtnHover: {
      backgroundColor: 'white',
      color: 'black',
      borderColor: 'white',
    },
    logoImage: 'logoDark',
  },
  {
    match: 'appearance-id-9',
    userBlock: {
      backgroundImage:
        'url("https://media0.giphy.com/media/GiXBNzilzkHH1GY7Vc/giphy.gif?cid=ecf05e47n2dbv0jt788lsb6lj6gf97hc9u2tjvf5v6s23dgt&rid=giphy.gif&ct=g")',
      color: 'black',
    },
    socialLinkBg: {
      backgroundColor: '#5f5f5f42',
    },
    customBtn: {
      color: 'black',
    },
    customBtnHover: {
      backgroundColor: 'black',
      color: 'white',
      borderColor: 'black',
    },
    logoImage: 'logoLight',
  },
  {
    match: 'appearance-id-10',
    userBlock: {
      backgroundImage:
        'url("https://media1.giphy.com/media/y9s5ybJoWEUznNGi51/giphy.gif?cid=ecf05e475ne8tng2bth4q5ophz7mol8dvym0utg2lo2dy2a3&rid=giphy.gif&ct=g")',
      color: 'white',
    },
    socialLinkBg: {
      backgroundColor: '#ffffff42',
    },
    customBtn: {
      color: 'white',
    },
    customBtnHover: {
      backgroundColor: 'white',
      color: 'lightblue',
      borderColor: 'white',
    },
    logoImage: 'logoDark',
  },
  {
    match: 'appearance-id-11',
    userBlock: {
      backgroundImage:
        'url("https://i.pinimg.com/originals/4a/ae/bf/4aaebf41f6b1f0a10991689cf0e90a19.gif")',
      color: 'white',
    },
    socialLinkBg: {
      backgroundColor: '#ffffff42',
    },
    customBtn: {
      color: 'white',
    },
    customBtnHover: {
      backgroundColor: 'white',
      color: '#FD5180',
      borderColor: 'white',
    },
    logoImage: 'logoDarkMono',
  },
  {
    match: 'appearance-id-12',
    userBlock: {
      backgroundImage:
        'url("https://i.pinimg.com/originals/64/48/49/6448496d47ba63fb70b201e9cd008f4e.gif")',
      color: 'black',
    },
    socialLinkBg: {
      backgroundColor: '#ffffff42',
    },
    customBtn: {
      color: 'black',
    },
    customBtnHover: {
      backgroundColor: 'black',
      color: 'white',
      borderColor: 'black',
    },
    logoImage: 'logoLightMono',
  },
];

export default appearanceDatas;
