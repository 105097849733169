import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentProfile } from '../../actions/profile';
import { logout } from '../../actions/auth';
import { TextField } from '@material-ui/core';

// ASSETS
import logoImg from '../../dist/images/logo-new-new.webp';
import onlyLogo from '../../dist/images/onlyLogo.webp';
import mostLayoutBg from '../../dist/images/layout_bg.png';
import logoDarkImg from '../../dist/images/logo-dark-new-new.webp';
import iconOnlineSvg from '../../dist/images/icon-customize webp.webp';
import iconBudgetSvg from '../../dist/images/icon-budgeting.webp';
import iconOnboardingSvg from '../../dist/images/icon-onboarding.webp';
import iconApiSvg from '../../dist/images/icon-api.webp';
import mockupVideo from '../../dist/images/out.mp4';
import mockupA from '../../dist/images/222.png';

const Landing = ({
  getCurrentProfile,
  profile: { profile, loading },
  auth: { user, isAuthenticated },
  logout,
}) => {
  // var loggedOut = false;
  // if (isAuthenticated) {
  //   return <Redirect to='/dashboard' />;
  // }
  useEffect(() => {
    // if (!loggedOut) {
    getCurrentProfile();
    // }
  }, []);

  if (document.getElementById('mockup-vid')) {
    document.getElementById('mockup-vid').play();
  }

  return (
    <section className='get-started'>
      {/* Starts here */}

      <header className='header' id='top'>
        <div className='overlay has-fade'></div>

        <nav className='container container--pall-head flex flex-jc-sb flex-ai-c'>
          <a href='/' className='header__logo'>
            <img src={logoImg} alt='dotCard' />
          </a>

          <a
            id='btnHamburger'
            // href='#'
            className='header__toggle hide-for-desktop'
          >
            <span></span>
            <span></span>
            <span></span>
          </a>

          <div className='header__links hide-for-mobile'>
            <a href='#how-to-use'>Create</a>
            <a href='#mock-up'>Design</a>
            <a href='#feature'>Share</a>
            <a href='#footer-scroll'>Contact</a>
          </div>
          <div className='sign-links hide-for-mobile'>
            {profile ? (
              <div class='dropdown'>
                <button class='dropbtn center-items'>
                  <div className='image-container full-center-items'>
                    <div
                      className='image'
                      style={{
                        backgroundImage: `url(${
                          // profile && profile.profileAvatar
                          profile.profileAvatar
                        }),url('https://i2.wp.com/www.msahq.org/wp-content/uploads/2016/12/default-avatar.png?ssl=1') `,
                        backgroundColor: 'red',
                      }}
                    ></div>
                  </div>
                  <div className='profile-name'>
                    {profile.profileName}
                    {/* {user.name} */}
                  </div>
                  <span
                    class='iconify'
                    data-icon='fe-arrow-down'
                    data-inline='false'
                  ></span>
                </button>
                <div class='dropdown-content'>
                  <Link to='/dashboard'>Dashboard</Link>
                  <Link
                    onClick={logout}
                    to='/'
                    style={{ textDecoration: 'none' }}
                  >
                    Logout
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <Link
                  to='/login'
                  className='header__cta hide-for-mobile'
                  id='sign-in-btn'
                >
                  Log In
                </Link>
                <Link
                  to='/register'
                  className='button header__cta hide-for-mobile'
                  style={{ marginRight: '1em' }}
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </nav>

        <div className='header__menu has-fade'>
          {profile ? (
            <>
              <Link to='/dashboard'>Dashboard</Link>
              <Link onClick={logout} to='/'>
                Logout
              </Link>
            </>
          ) : (
            <>
              <Link to='/register'>Sign Up</Link>
              <Link to='/login'>Log In</Link>
            </>
          )}
        </div>
      </header>

      <section className='hero'>
        <div className='container'>
          <div className='first-impressions center-items'>
            <div className='create-design-share'>
              {/* <div className='center-items'>Create.</div>
              <div className='center-items design-homepage'>Design.</div>
              <div className='center-items'>Share.</div> */}
              <span class='heading-line heading-line-first'>
                <span class='heading-line-gradient'>Create.</span>
              </span>
              <span class='heading-line heading-line-second'>
                <span class='heading-line-gradient'>Design.</span>
              </span>
              <span class='heading-line heading-line-third'>
                <span class='heading-line-gradient'>Share.</span>
              </span>
            </div>
            <div className='sub-title-first-impression'>
              A free digital card to hold all your social/custom links. <br />{' '}
              dotCard provides the platform to create such card, and share it
              using a QR Code
            </div>
            <div className='first-impression-buttons'>
              <div className='links-buttons'>
                <div className='start-creating center-items'>
                  <Link to='/register'> Create dotCard</Link>
                </div>
                <div className='get-a-demo center-items' style={{}}>
                  <a
                    href='https://www.youtube.com/watch?v=SY98jpOVxh8&t=28s'
                    target='_blank'
                  >
                    Get a demo
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='hero__image'></div>

          <div className='hero__text container--pall'>
            <h2>Become a professional at what you do</h2>
            <p>
              In a world full of social media, we all need a card that contains
              all our social media.
            </p>
            <Link to='/register' className='button hero__cta'>
              Get Started
            </Link>
          </div> */}
        </div>
      </section>
      <section className='builder-layout'>
        <img src={mostLayoutBg} alt='asd' width='100%' />
      </section>

      {/* <section className='create-dotcard' id='how-to-use'>
        <div className='create-dotcard-container'>
          <div className='title'>
            <span className='minor-card'>CREATE YOUR DOTCARD</span>
            <br />
            <h2 className='title-header'>How the app works</h2>
          </div>
          <div className='mockup-disp full-center-items'>
            <img src={mockupA} />
          </div>
          <div className='steps full-center-items'>
            <div className='step-container'>
              <div className='step step-non-highlight'>
                <div className='step-icon center-items'>
                  <img src={iconOnlineSvg} />
                </div>
                <div className='step-content'>
                  <div className='step-head'>1. Start</div> <br />
                  <div className='step-details'>
                    Go to GET STARTED and create your account. Add your social
                    and other links to your account.
                  </div>
                </div>
              </div>
              <div className='step step-highlight'>
                <div className='step-icon center-items'>
                  <img src={iconOnlineSvg} />
                </div>
                <div className='step-content'>
                  <div className='step-head'>2. Design</div> <br />
                  <div className='step-details'>
                    Select a theme by your choice and give your dotCard a
                    personal touch.
                  </div>
                </div>
              </div>
              <div className='step step-non-highlight'>
                <div className='step-icon center-items'>
                  <img src={iconOnlineSvg} />
                </div>
                <div className='step-content'>
                  <div className='step-head'>3. Share</div> <br />
                  <div className='step-details'>
                    You are almost done. Start sharing with people and let them
                    know that you are a professional at what you do.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className='video-mockup-container' id='mock-up'>
        <video muted loop id='mockup-vid'>
          <source src={mockupVideo} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </section>
      {/* <section className='articles' id='contact'>
        <div className='contact-form-container'>
          <div className='right-side'>
            <div className='title full-center-items'>CONTACT US</div>
            <div className='contact-form'>
              <form className='center-items'>
                <TextField
                  id='outlined-basic'
                  label='Name'
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  id='outlined-basic'
                  label='Message'
                  variant='outlined'
                  fullWidth
                  multiline
                  rows={6}
                />
                <span className='full-center-items'>
                  <button className='button'>Send Email</button>
                </span>
              </form>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className='feature' id='feature'>
        <div className='feature__content container container--pall'>
          <div className='feature__intro'>
            <span className='minor-card'>FEATURES</span> <br />
            <br />
            <h2>Overview</h2>
           
          </div>

          <div className='feature__grid'>
            <div className='feature__item'>
              <div className='feature__icon center-items'>
                <img src={iconOnlineSvg} />
              </div>
              <div className='feature__title'>Themes</div>
              <div className='feature__description'>
                Get new themes for your dotCard. Make your card look new every
                day.
              </div>
            </div>

            <div className='feature__item'>
              <div className='feature__icon center-items'>
                <img src={iconBudgetSvg} />
              </div>
              <div className='feature__title'>Profile</div>
              <div className='feature__description'>
                In the settings, you can update your profile photo, your
                location and name too!
              </div>
            </div>

            <div className='feature__item'>
              <div className='feature__icon center-items'>
                <img src={iconOnboardingSvg} />
              </div>
              <div className='feature__title'>Social Links</div>
              <div className='feature__description'>
                All your social media links can be added and will be displayed
                with a icon on your dotCard.
              </div>
            </div>
            <div className='feature__item'>
              <div className='feature__icon center-items'>
                <img src={iconOnboardingSvg} />
              </div>
              <div className='feature__title'>Other Links</div>
              <div className='feature__description'>
                Add any link other than social media links to Custom Links, and
                it will be displayed on your dotCard.
              </div>
            </div>
            <div className='feature__item'>
              <div className='feature__icon center-items'>
                <img src={iconOnboardingSvg} />
              </div>
              <div className='feature__title'>QR Code</div>
              <div className='feature__description'>
                Share your card with a QR Code. Open a camera and scan the QR
                code to directly visit your dotCard.
              </div>
            </div>

            <div className='feature__item'>
              <div className='feature__icon center-items'>
                <img src={iconApiSvg} />
              </div>
              <div className='feature__title'>24x7 Support</div>
              <div className='feature__description'>
                Get technical support directly through email.
              </div>
            </div>
          </div>
        </div>
      </section>*/}
      <section className='footer-try' id='footer-scroll'>
        <div className='follow-section'>
          <div className='logo-footer full-center-items'>
            <img src={logoImg} alt='' height='40px' width='auto' />
            <span style={{ textAlign: 'center' }}>by Prakarsh Gupta</span>
          </div>
        </div>
        <div className='back-to-top full-center-items'>
          <a href='#top'> BACK TO TOP ▲</a>
        </div>
        <div className='copyright full-center-items'>
          <span>
            <span className='footer__copyright'>
              Copyright © 2022 dotCard Inc. All rights reserved.
            </span>
          </span>
        </div>
      </section>
      {/* <footer className='footer-home-container'>
        <div className='footer-home'>
          <div className='logo-footer full-center-items'>
            <img src={logoDarkImg} alt='' height='40px' width='auto' />
          </div>
          <div className='social-footer center-items'>
            <div className='social-icons'>
              <span>Email</span>
              <span>INSTAGRAM</span>
              <span>Facebook</span>
            </div>
          </div>

          <div className='copyright full-center-items'>
            <span>
              <span className='footer__copyright'>
                Copyright &copy; dotCard | All Rights Reserved by {'  '}
              </span>
              <span className='created-by'>
                <a
                  href='https://www.linkedin.com/in/prakarshgupta/'
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  PRAKARSH GUPTA
                </a>
              </span>
            </span>
          </div>
        </div>
      </footer> */}
    </section>
  );
};

Landing.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile, logout })(Landing);
