import React, { useState, useEffect, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  createProfile,
  getCurrentProfile,
  deleteAccount,
} from '../../actions/profile';
import { logout } from '../../actions/auth';

// Material UI
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: '#ff0000',
  },
  button: {
    backgroundColor: '#0167ff',
    '&:hover': {
      backgroundColor: '#0057da',
    },
    '&:active': {
      backgroundColor: '#0057da',
    },
    margin: '0.5em 1em',
    padding: '0.5em 1em',
  },
}));

const EditSettings = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  history,
  logout,
  deleteAccount,
}) => {
  const classes = useStyles();
  // const useStyles = makeStyles({
  //   textfield: {
  //     // background: 'red',
  //     // borderRadius: '10px',
  //     width: '80%',
  //   },
  //   input: {
  //     background: 'blue',
  //     padding: '0',
  //     margin: '0',
  //     color: 'white',
  //   },
  // });
  // const classes = useStyles();

  const [formData, setFormData] = useState({
    profileName: '',
    profileAvatar: '',
    location: '',
    appearanceId: '',
    email: '',
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: '',
    linkedin: '',
    whatsapp: '',
    mobileNo: '',
    twitch: '',
    tiktok: '',
    discord: '',
    snapchat: '',
  });
  useEffect(() => {
    getCurrentProfile();
    setFormData({
      profileName: loading || !profile.profileName ? '' : profile.profileName,
      profileAvatar:
        loading || !profile.profileAvatar ? '' : profile.profileAvatar,
      location: loading || !profile.location ? '' : profile.location,
      appearanceId:
        loading || !profile.appearanceId ? '' : profile.appearanceId,
      email: loading || !profile.socialLinks ? '' : profile.socialLinks.email,
      facebook:
        loading || !profile.socialLinks ? '' : profile.socialLinks.facebook,
      twitter:
        loading || !profile.socialLinks ? '' : profile.socialLinks.twitter,
      instagram:
        loading || !profile.socialLinks ? '' : profile.socialLinks.instagram,
      youtube:
        loading || !profile.socialLinks ? '' : profile.socialLinks.youtube,
      linkedin:
        loading || !profile.socialLinks ? '' : profile.socialLinks.linkedin,
      whatsapp:
        loading || !profile.socialLinks ? '' : profile.socialLinks.whatsapp,
      mobileNo:
        loading || !profile.socialLinks ? '' : profile.socialLinks.mobileNo,
      twitch: loading || !profile.socialLinks ? '' : profile.socialLinks.twitch,
      tiktok: loading || !profile.socialLinks ? '' : profile.socialLinks.tiktok,
      discord:
        loading || !profile.socialLinks ? '' : profile.socialLinks.discord,
      snapchat:
        loading || !profile.socialLinks ? '' : profile.socialLinks.snapchat,
    });
  }, [loading]);
  const { profileName, profileAvatar, location } = formData;

  // const onClick = (e) => {
  //   setFormData({ ...formData, appearanceId: e.target.getAttribute('id') });
  // };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmitSettings = () => {
    // console.log(formData);
    createProfile(formData, history, true);
  };
  return (
    <Fragment>
      <form className='app-form'>
        <div className='title center-items'>
          <span> Settings </span>
          <div className='save-btn center-items'>
            <Link
              onClick={logout}
              to='/login'
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant='contained'
                color='primary'
                size='small'
                startIcon={<ExitToAppIcon />}
                className={classes.button}
                disableElevation
              >
                Logout
              </Button>
            </Link>
          </div>
        </div>
        <div className='data-all'>
          <div className='data-settings'>
            <div className='profile-display center-items'>
              <div className='profile-image center-items'>
                <div
                  className='image'
                  style={{
                    backgroundImage: `url(${profileAvatar}),url('https://i2.wp.com/www.msahq.org/wp-content/uploads/2016/12/default-avatar.png?ssl=1') `,
                  }}
                ></div>
              </div>
              <div className='profile-name center-items'>
                <p>{profileName}</p>
              </div>
              <div className='profile-location center-items'>
                <p>{location}</p>
              </div>
            </div>
            <div className='edit-details'>
              <div className='details-title'>
                <span className='title-text center-items'>
                  Edit your details:
                </span>
                <span className='save-details center-items'>
                  {/* <button className='save-detail-btn'>Save</button> */}
                  <Button
                    onClick={() => onSubmitSettings()}
                    variant='contained'
                    color='primary'
                    size='small'
                    startIcon={<SaveIcon />}
                    className={classes.button}
                    disableElevation
                  >
                    Save
                  </Button>
                </span>
              </div>
              <div className='details-form-container'>
                <form noValidate autoComplete='off' className='details-form'>
                  <TextField
                    name='profileName'
                    value={profileName}
                    onChange={(e) => onChange(e)}
                    id='outlined-basic'
                    label='Name'
                    variant='outlined'
                    size='small'
                    fullWidth
                  />
                  <TextField
                    name='profileAvatar'
                    value={profileAvatar}
                    onChange={(e) => onChange(e)}
                    id='outlined-basic'
                    label='Avatar URL'
                    variant='outlined'
                    size='small'
                    fullWidth
                  />
                  <TextField
                    name='location'
                    value={location}
                    onChange={(e) => onChange(e)}
                    id='outlined-basic'
                    label='Location'
                    variant='outlined'
                    size='small'
                    fullWidth
                  />
                </form>
              </div>
            </div>
            <div className='delete-account-container center-items'>
              <div className='delete-account-title'>Delete your account</div>
              <div className='delete-account-btn center-items'>
                <Button
                  onClick={() => deleteAccount()}
                  variant='contained'
                  color='secondary'
                  size='small'
                  startIcon={<DeleteIcon />}
                  className={classes.deleteButton}
                  disableElevation
                >
                  Delete
                </Button>
              </div>
              <div className='delete-account-sub'>
                You'll permanently loose your website, links and more...
              </div>
            </div>
          </div>
          {/* <div className='data-settings center-items'>
            <div className='input-name'>
            <span className='label center-items'>Name: </span>
            <input
            type='text'
            // placeholder='Name'
            name='profileName'
            value={profileName}
            onChange={(e) => onChange(e)}
            />
            </div>
            <div className='input-avatar'>
            <span className='label center-items'>Avatar: </span>
              <input
                type='text'
                name='profileAvatar'
                value={profileAvatar}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='input-location'>
              <span className='label center-items'>Location: </span>
              <input
                type='text'
                name='location'
                // placeholder='Location'
                value={location}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div> */}
        </div>
      </form>
    </Fragment>
  );
};

EditSettings.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, {
  createProfile,
  getCurrentProfile,
  logout,
  deleteAccount,
})(withRouter(EditSettings));
