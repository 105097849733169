import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteCustomLink } from '../../actions/profile';

// Material UI
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: '#ff0000',
  },
}));

const DispCustomLink = ({ customLinkArray, deleteCustomLink }) => {
  const classes = useStyles();
  const customLinks = customLinkArray.map((lnk) => (
    <div key={lnk._id} className='single-custom-link'>
      <div className='link-title center-items'>
        <p>{lnk.title}</p>
      </div>
      <div className='link-url center-items'>
        <p>https://{lnk.customURL}</p>
      </div>
      <div className='del-btn center-items'>
        <Button
          onClick={() => deleteCustomLink(lnk._id)}
          variant='outlined'
          color='secondary'
          size='small'
          startIcon={<DeleteIcon />}
          className={classes.deleteButton}
          disableElevation
        >
          Delete
        </Button>
      </div>
    </div>
  ));
  return (
    <Fragment>
      <div className='disp-custom'>{customLinks}</div>
    </Fragment>
  );
};

DispCustomLink.propTypes = {
  customLinkArray: PropTypes.array.isRequired,
  deleteCustomLink: PropTypes.func.isRequired,
};

export default connect(null, { deleteCustomLink })(DispCustomLink);
