import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfileById } from '../../actions/profile';
import appearanceDatas from '../../utils/appearanceInfo';

import logoDark from '../../dist/images/logo-dark-new-new.webp';
import logoLight from '../../dist/images/logo-new-new.webp';
import logoDarkMono from '../../dist/images/logo-dark-mono.webp';
import logoLightMono from '../../dist/images/logo-light-mono.webp';

import { StyleSheet, css } from 'aphrodite';
// Material UI
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const OnlyFacebook = ({ socialLink }) => {
  if (socialLink) {
    var facebook_link = `https://www.facebook.com/${socialLink}`;
    return (
      <a href={facebook_link}>
        <span
          className='iconify'
          data-icon='entypo-social:facebook'
          data-inline='false'
        ></span>
      </a>
    );
  } else return <></>;
};
const OnlyInstagram = ({ socialLink }) => {
  if (socialLink) {
    var newSocialLink = socialLink;
    if (socialLink[0] === '@') {
      newSocialLink = socialLink.slice(1);
    }
    const instagramLink = `https://www.instagram.com/${newSocialLink}`;
    return (
      <a href={instagramLink}>
        <span
          className='iconify'
          data-icon='akar-icons:instagram-fill'
          data-inline='false'
        ></span>
      </a>
    );
  } else return <></>;
};
const OnlySnapchat = ({ socialLink }) => {
  if (socialLink) {
    var snap_link = `https://www.snapchat.com/add/${socialLink}`;
    return (
      <a href={snap_link}>
        <span
          className='iconify'
          data-icon='mdi:snapchat'
          data-inline='false'
        ></span>
      </a>
    );
  } else return <></>;
};
const OnlyTwitter = ({ socialLink }) => {
  if (socialLink) {
    var newSocialLink = socialLink;
    if (socialLink[0] === '@') {
      newSocialLink = socialLink.slice(1);
    }
    const twitterLink = `https://www.twitter.com/${newSocialLink}`;
    return (
      <a href={twitterLink}>
        <span
          className='iconify'
          data-icon='radix-icons:twitter-logo'
          data-inline='false'
        ></span>
      </a>
    );
  } else return <></>;
};
const OnlyEmail = ({ socialLink }) => {
  if (socialLink) {
    const email_link = `mailto:${socialLink}`;
    return (
      <a href={email_link}>
        <span
          className='iconify'
          data-icon='clarity:email-line'
          data-inline='false'
          value
        ></span>
      </a>
    );
  } else return <></>;
};
const OnlyWhatsapp = ({ socialLink }) => {
  if (socialLink) {
    var whatsapp_link = `tel:${socialLink}`;
    return (
      <a href={whatsapp_link}>
        <span
          className='iconify'
          data-icon='bi:whatsapp'
          data-inline='false'
        ></span>
      </a>
    );
  } else return <></>;
};
const OnlyLinkedin = ({ socialLink }) => {
  if (socialLink) {
    var linkedIn_link = `https://www.linkedin.com/in/${socialLink}`;
    return (
      <a href={linkedIn_link}>
        <span
          className='iconify'
          data-icon='ls:linkedin'
          data-inline='false'
        ></span>
      </a>
    );
  } else return <></>;
};
const OnlyYoutube = ({ socialLink }) => {
  if (socialLink) {
    var youtube_link = `https://www.youtube.com/channel/${socialLink}`;
    return (
      <a href={youtube_link}>
        <span
          className='iconify'
          data-icon='bi:youtube'
          data-inline='false'
        ></span>
      </a>
    );
  } else return <></>;
};
const OnlyTwitch = ({ socialLink }) => {
  if (socialLink) {
    var twitch_link = `https://www.twitch.tv/${socialLink}`;
    return (
      <a href={twitch_link}>
        <span
          className='iconify'
          data-icon='dashicons:twitch'
          data-inline='false'
        ></span>
      </a>
    );
  } else return <></>;
};
const OnlyTiktok = ({ socialLink }) => {
  if (socialLink) {
    var tiktok_link = `https://tiktok.com/@${socialLink}`;
    return (
      <a href={tiktok_link}>
        <span
          className='iconify'
          data-icon='cib:tiktok'
          data-inline='false'
        ></span>
      </a>
    );
  } else return <></>;
};
const OnlyDiscord = ({ socialLink }) => {
  if (socialLink) {
    var discord_link = `https://discord.gg/${socialLink}`;
    return (
      <a href={discord_link}>
        <span
          className='iconify'
          data-icon='simple-icons:discord'
          data-inline='false'
        ></span>
      </a>
    );
  } else return <></>;
};

const DispBlock = ({ getProfileById, profile: { profile, loading } }) => {
  const { user_id } = useParams();
  let appearanceId = 'appearance-id-1';
  useEffect(() => {
    getProfileById(user_id);
  }, []);

  if (profile) {
    appearanceId = profile.appearanceId;
    const appearanceData = appearanceDatas.find((app) => {
      if (app.match == appearanceId) {
        return app;
      }
    });
    const styles = StyleSheet.create({
      hoverBtn: {
        backgroundColor: 'red',
        border: '2px solid',
        background: 'none',
        borderRadius: '10px',
        fontWeight: '500',
        padding: '0.8em 1em',
        fontSize: '20px',
        textAlign: 'center',
        marginBottom: '1em',
        transition: '0.2s all',
        display: 'grid',
        justifyContent: 'center',

        color: appearanceData.customBtn.color,
        ':hover': appearanceData.customBtnHover,
      },
      btn_p: {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    });
    var logoImage = logoDark;
    if (appearanceData) {
      if (appearanceData.logoImage === 'logoDarkMono') logoImage = logoDarkMono;
      if (appearanceData.logoImage === 'logoDark') logoImage = logoDark;
      if (appearanceData.logoImage === 'logoLight') logoImage = logoLight;
      if (appearanceData.logoImage === 'logoLightMono')
        logoImage = logoLightMono;
    }

    return (
      <Fragment>
        <div className='user-block' style={appearanceData.userBlock}>
          <Container maxWidth='sm' className='profile-container'>
            <div className='user-data'>
              <div className='user-avatar center-items'>
                <div
                  className='avatar-image'
                  style={{
                    backgroundImage: `url(${profile.profileAvatar}),url('https://i2.wp.com/www.msahq.org/wp-content/uploads/2016/12/default-avatar.png?ssl=1') `,
                  }}
                ></div>
              </div>
              <div className='user-name center-items'>
                <p>{profile.profileName}</p>
              </div>
              {/* {profile.} */}
              <div className='user-location center-items'>
                <div className='center-items'>
                  {profile.location ? (
                    <span
                      class='iconify'
                      data-icon='carbon:location'
                      data-inline='false'
                    ></span>
                  ) : (
                    <></>
                  )}
                  <p>{profile.location}</p>
                </div>
              </div>
            </div>
            <div className='user-social-links center-items'>
              <div style={appearanceData.socialLinkBg}>
                <OnlyFacebook
                  socialLink={
                    profile.socialLinks && profile.socialLinks.facebook
                  }
                />
                <OnlyInstagram
                  socialLink={
                    profile.socialLinks && profile.socialLinks.instagram
                  }
                />
                <OnlySnapchat
                  socialLink={
                    profile.socialLinks && profile.socialLinks.snapchat
                  }
                />
                <OnlyTwitter
                  socialLink={
                    profile.socialLinks && profile.socialLinks.twitter
                  }
                />
                <OnlyEmail
                  socialLink={profile.socialLinks && profile.socialLinks.email}
                />
                <OnlyWhatsapp
                  socialLink={
                    profile.socialLinks && profile.socialLinks.whatsapp
                  }
                />
                <OnlyLinkedin
                  socialLink={
                    profile.socialLinks && profile.socialLinks.linkedin
                  }
                />
                <OnlyYoutube
                  socialLink={
                    profile.socialLinks && profile.socialLinks.youtube
                  }
                />
                <OnlyTwitch
                  socialLink={profile.socialLinks && profile.socialLinks.twitch}
                />
                <OnlyTiktok
                  socialLink={profile.socialLinks && profile.socialLinks.tiktok}
                />
                <OnlyDiscord
                  socialLink={
                    profile.socialLinks && profile.socialLinks.discord
                  }
                />
              </div>
            </div>
            <div className='user-custom-links'>
              {profile.customLinks.map((custom_link) => {
                var newCustomLink = `https://${custom_link.customURL}`;
                return (
                  <a href={newCustomLink}>
                    <div
                      className='custom-btn'
                      className={css(styles.hoverBtn)}
                    >
                      <p className={css(styles.btn_p)}>{custom_link.title}</p>
                    </div>
                  </a>
                );
              })}
            </div>
            <div className='footer'>
              <a href={window.location.origin}>
                <div
                  className='logo-area'
                  style={{ backgroundImage: `url(${logoImage})` }}
                ></div>
              </a>
            </div>
          </Container>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Spinner />
      </Fragment>
    );
  }
};

DispBlock.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfileById })(DispBlock);
