import React, { Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addCustomLink } from '../../actions/profile';

// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#0167ff',
    margin: '0.5em',
    '&:hover': {
      backgroundColor: '#0057da',
    },
    '&:active': {
      backgroundColor: '#0057da',
    },
  },
}));

const AddCustomLink = ({ addCustomLink, history }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    title: '',
    customURL: '',
  });
  const { title, customURL } = formData;

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmitCustom = (e) => {
    e.preventDefault();

    // console.log('ads');
    addCustomLink(formData, history);
    setFormData({
      title: '',
      customURL: '',
    });
  };
  return (
    <Fragment>
      <div className='add-custom-link-container'>
        <div className='custom-url-title'>
          <TextField
            name='title'
            value={title}
            onChange={(e) => onChange(e)}
            id='outlined-basic'
            label='Title'
            variant='outlined'
            size='small'
            required
            fullWidth
          />
        </div>
        <div className='custom-url-link'>
          <TextField
            name='customURL'
            value={customURL}
            onChange={(e) => onChange(e)}
            id='outlined-basic'
            label='URL'
            variant='outlined'
            size='small'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>https://</InputAdornment>
              ),
            }}
            required
            fullWidth
          />
        </div>
        <div className='add-btn center-items'>
          <Button
            variant='contained'
            onClick={(e) => onSubmitCustom(e)}
            color='primary'
            size='medium'
            startIcon={<AddCircleOutlineIcon />}
            className={classes.button}
            disableElevation
          >
            Add
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

AddCustomLink.propTypes = {
  addCustomLink: PropTypes.func.isRequired,
};

export default connect(null, { addCustomLink })(withRouter(AddCustomLink));
