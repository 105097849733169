import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { TextField } from '@material-ui/core';
import logoImage from '../../dist/images/logo-new-new.webp';

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();
    login({ email, password });
    // console.log('success');
  };

  //Redirect if authenticated
  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }
  return (
    <section class='login full-center-items'>
      <div className='logo'>
        <a href='https://mydotcard.com/'>
          <img src={logoImage} alt='dotCard' />
        </a>
      </div>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className='login-container'>
          <span className='full-center-items'>
            <h1>Log In</h1>
          </span>
          <span className='form-container center-items'>
            <TextField
              id='outlined-basic'
              label='Email'
              variant='outlined'
              fullWidth
              type='email'
              name='email'
              value={email}
              onChange={(e) => onChange(e)}
              required
            />
            <TextField
              id='outlined-basic'
              label='Password'
              variant='outlined'
              fullWidth
              type='password'
              name='password'
              value={password}
              onChange={(e) => onChange(e)}
              required
            />
            <span className='full-center-items'>
              <span>
                <button type='submit' className='button'>
                  Log In
                </button>
              </span>
            </span>
          </span>
          <span className='sign-up-link full-center-items'>
            <span>
              Don't have an account? <Link to='/register'>Sign Up</Link>
            </span>
          </span>
        </div>
      </form>

      {/* <h1 className='large text-primary'>Sign In</h1>
      <p className='lead'>
        <i className='fas fa-user'></i> Sign in to your account
      </p>
      <form className='form' onSubmit={(e) => onSubmit(e)}>
        <div className='form-group'>
          <input
            type='email'
            placeholder='Email Address'
            name='email'
            value={email}
            onChange={(e) => onChange(e)}
            // required
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder='Password'
            name='password'
            value={password}
            onChange={(e) => onChange(e)}
            // minLength='6'
          />
        </div>
        <input type='submit' className='btn-submit' value='Login' />
      </form>
      <p className='my-1'>
        Don't have an account? <Link to='/register'>Sign Up</Link>
      </p> */}
    </section>
  );
};
Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};
const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStatetoProps, { login })(Login);
