import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';
import { getCurrentProfile } from '../../actions/profile';
import { TextField } from '@material-ui/core';
import logoImage from '../../dist/images/logo-new-new.webp';
const Register = ({
  getCurrentProfile,
  profile: { profile, loading },
  setAlert,
  register,
  isAuthenticated,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
  });
  useEffect(() => {
    // getCurrentProfile();
  }, []);
  //Redirect if authenticated
  if (isAuthenticated) {
    // return <Redirect to='/create-profile' />;
    if (profile) {
      if (profile.appearanceId) {
        return <Redirect to='/dashboard' />;
      }
    } else {
      const create_profile_link = `/create-profile/${formData.name}/${formData.email}`;
      return <Redirect to={create_profile_link} />;
    }
  }
  const { name, email, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match', 'danger', 3000);
    } else {
      register({ name, email, password });
      // console.log('success');
    }
  };

  return (
    <section class='login center-items'>
      <div className='logo'>
        <a href='https://mydotcard.com/'>
          <img src={logoImage} alt='dotCard' />
        </a>
      </div>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className='register-container'>
          <span className='full-center-items'>
            <h1>Create a new account</h1>
          </span>
          <span className='form-container center-items'>
            <TextField
              id='outlined-basic'
              label='Name'
              variant='outlined'
              name='name'
              value={name}
              onChange={(e) => onChange(e)}
              fullWidth
              required
            />
            <TextField
              id='outlined-basic'
              label='Email'
              variant='outlined'
              fullWidth
              type='email'
              name='email'
              value={email}
              onChange={(e) => onChange(e)}
              required
            />
            <TextField
              id='outlined-basic'
              label='Password'
              variant='outlined'
              fullWidth
              type='password'
              name='password'
              value={password}
              onChange={(e) => onChange(e)}
              required
            />
            <TextField
              id='outlined-basic'
              label='Confirm Password'
              variant='outlined'
              fullWidth
              type='password'
              name='password2'
              value={password2}
              onChange={(e) => onChange(e)}
              required
            />
            <span className='full-center-items'>
              <span>
                <button type='submit' className='button'>
                  Sign Up
                </button>
              </span>
            </span>
          </span>
          <span className='sign-up-link full-center-items'>
            <span>
              Already have an account? <Link to='/login'>Sign In</Link>
            </span>
          </span>
        </div>
      </form>

      {/* <h1 className='large text-primary'>Sign Up</h1>
      <p className='lead'>
        <i className='fas fa-user'></i> Create Your Account
      </p>
      <form className='form' onSubmit={(e) => onSubmit(e)}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Name'
            name='name'
            value={name}
            onChange={(e) => onChange(e)}
            // required
          />
        </div>
        <div className='form-group'>
          <input
            type='email'
            placeholder='Email Address'
            name='email'
            value={email}
            onChange={(e) => onChange(e)}
            // required
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder='Password'
            name='password'
            value={password}
            onChange={(e) => onChange(e)}
            // minLength='6'
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder='Confirm Password'
            name='password2'
            value={password2}
            onChange={(e) => onChange(e)}
            // minLength='6'
          />
        </div>
        <input type='submit' className='btn-submit' value='Register' />
      </form>
      <p className='my-1'>
        Already have an account? <Link to='/login'>Sign In</Link>
      </p> */}
    </section>
  );
};

Register.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};
const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  profile: state.profile,
});

export default connect(mapStatetoProps, {
  getCurrentProfile,
  setAlert,
  register,
})(Register);
