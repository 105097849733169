import React, { useState, Fragment, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile } from '../../actions/profile';

const CreateProfile = ({ createProfile, history, auth: { user } }) => {
  const { user_name, user_email } = useParams();
  const [formData, setFormData] = useState({
    location: '',
    email: user_email,
    facebook: '',
    appearanceId: 'appearance-id-4',
    profileAvatar: '',
    profileName: user_name,
  });
  const { location, facebook, email } = formData;
  useEffect(() => {
    setFormData({
      location: '',
      email: user_email,
      facebook: '',
      appearanceId: 'appearance-id-4',
      profileAvatar: '',
      profileName: user_name,
    });
    createProfile(formData, history);
  }, []);

  return <div></div>;
};

CreateProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { createProfile })(
  withRouter(CreateProfile)
);
