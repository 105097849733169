import React, { useState, useEffect, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile, getCurrentProfile } from '../../actions/profile';
import appearanceDatas from '../../utils/appearanceInfo';

// Material UI
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#0167ff',
    '&:hover': {
      backgroundColor: '#0057da',
    },
    '&:active': {
      backgroundColor: '#0057da',
    },
    margin: '1em',
    padding: '0.5em 1em',
  },
}));

const EditCustomLinks = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  history,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    appearanceId: '',
    email: '',
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: '',
    linkedin: '',
    whatsapp: '',
    mobileNo: '',
    twitch: '',
    tiktok: '',
    discord: '',
    snapchat: '',
  });
  useEffect(() => {
    getCurrentProfile();
    setFormData({
      appearanceId:
        loading || !profile.appearanceId ? '' : profile.appearanceId,
      email: loading || !profile.socialLinks ? '' : profile.socialLinks.email,
      facebook:
        loading || !profile.socialLinks ? '' : profile.socialLinks.facebook,
      twitter:
        loading || !profile.socialLinks ? '' : profile.socialLinks.twitter,
      instagram:
        loading || !profile.socialLinks ? '' : profile.socialLinks.instagram,
      youtube:
        loading || !profile.socialLinks ? '' : profile.socialLinks.youtube,
      linkedin:
        loading || !profile.socialLinks ? '' : profile.socialLinks.linkedin,
      whatsapp:
        loading || !profile.socialLinks ? '' : profile.socialLinks.whatsapp,
      mobileNo:
        loading || !profile.socialLinks ? '' : profile.socialLinks.mobileNo,
      twitch: loading || !profile.socialLinks ? '' : profile.socialLinks.twitch,
      tiktok: loading || !profile.socialLinks ? '' : profile.socialLinks.tiktok,
      discord:
        loading || !profile.socialLinks ? '' : profile.socialLinks.discord,
      snapchat:
        loading || !profile.socialLinks ? '' : profile.socialLinks.snapchat,
    });
  }, [loading]);

  const onClick = (e) => {
    setFormData({ ...formData, appearanceId: e.target.getAttribute('id') });
  };
  const onSubmitAppearance = () => {
    // console.log(formData);
    createProfile(formData, history, true);
  };
  return (
    <Fragment>
      <form className='app-form'>
        <div className='title center-items'>
          <span> Appearance </span>
          <div className='save-btn center-items'>
            {/* <button onClick={() => onSubmitAppearance()} className='btn-style'>
              <p>Save</p>
            </button> */}
            <Button
              variant='contained'
              onClick={() => onSubmitAppearance()}
              color='primary'
              size='small'
              startIcon={<SaveIcon />}
              className={classes.button}
              disableElevation
            >
              Save
            </Button>
          </div>
        </div>
        <div className='data-all data-appearance'>
          <div className='data-select'>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-1'
                style={appearanceDatas[0].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-2'
                style={appearanceDatas[1].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-3'
                style={appearanceDatas[2].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-4'
                style={appearanceDatas[3].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-5'
                style={appearanceDatas[4].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-6'
                style={appearanceDatas[5].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-7'
                style={appearanceDatas[6].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-8'
                style={appearanceDatas[7].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-9'
                style={appearanceDatas[8].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-10'
                style={appearanceDatas[9].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-11'
                style={appearanceDatas[10].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div
                className='tile'
                id='appearance-id-12'
                style={appearanceDatas[11].userBlock}
                onClick={(e) => onClick(e)}
              >
                <div class='indicators'>
                  <div class='on'>
                    <svg
                      width='16'
                      height='16'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsSvg='http://www.w3.org/2000/svg'
                    >
                      <g>
                        <title>Checked Cirle</title>
                        <path
                          id='svg_1'
                          d='m8,0c-4.4,0 -8,3.6 -8,8s3.6,8 8,8s8,-3.6 8,-8s-3.6,-8 -8,-8zm-1.3,11.5l-3.3,-3.4l1.4,-1.4l1.9,1.9l4.1,-4.1l1.4,1.4l-5.5,5.6z'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            {document.querySelectorAll('.tile').forEach((item) => {
              if (item.id === formData.appearanceId) {
                item.classList.add('selected');
              }
              item.addEventListener('click', (event) => {
                // 'unselect' all
                document.querySelectorAll('.tile.selected').forEach((elem) => {
                  elem.classList.remove('selected');
                });

                // select the target
                if (item.classList.contains('selected')) {
                  item.classList.remove('selected');
                } else {
                  item.classList.add('selected');
                }
              });
            })}
          </div>
        </div>
      </form>
    </Fragment>
  );
};

EditCustomLinks.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  withRouter(EditCustomLinks)
);
