import React, { useEffect, Fragment, useState } from 'react';
import Navbar from './Navbar';
import EditSocial from '../profile-form/EditSocial';
import EditCustomLinks from '../profile-form/EditCustomLinks';
import EditAppearance from '../profile-form/EditAppearance';
import EditSettings from '../profile-form/EditSettings';
import SupportPage from '../layout/SupportPage';
import Spinner from '../layout/Spinner';
import QRCode from 'qrcode.react';

// Material UI
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import {
  Link,
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import PrivateRoute from '../routing/PrivateRoute';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentProfile } from '../../actions/profile';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '1em',
    padding: '0.5em 1em',
    backgroundColor: '#0167ff',
    '&:hover': {
      backgroundColor: '#0057da',
    },
    '&:active': {
      backgroundColor: '#0057da',
    },
  },
}));

const Dashboard = ({
  getCurrentProfile,
  profile: { profile, loading },
  auth: { user },
}) => {
  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(true);
  let block_url = window.location.origin + '/user/';

  useEffect(() => {
    getCurrentProfile();
  }, []);
  const setBlockUrl = () => {
    if (profile) {
      block_url = window.location.origin + '/user/' + user._id;
    }
    return <> </>;
  };
  const QrCodeUser = ({ isHidden }) => {
    if (!isHidden) {
      if (document.getElementById('app-disp-cont')) {
        document.getElementById('app-disp-cont').style.filter = 'blur(5px)';
      }
      return (
        <div className='qr-code-container center-items'>
          <div className='qr-code-bg'></div>
          <div className='qr-code'>
            <QRCode value={block_url} includeMargin={true} size={270} />
          </div>
        </div>
      );
    } else {
      if (document.getElementById('app-disp-cont')) {
        document.getElementById('app-disp-cont').style.filter = 'blur(0px)';
      }
      return <> </>;
    }
  };
  // const userId =
  return (
    // <Router>
    <Fragment>
      {loading && profile === null ? (
        <Spinner />
      ) : (
        <Fragment>
          {user && user._id ? setBlockUrl() : <></>}
          {profile !== null ? (
            <Fragment>
              <div className='dashboard-app'>
                <Navbar />
                {/* <div className='greet center-items'>
                  <p>Hi, {user && user.name}</p>
                </div> */}
                <div className='app-body'>
                  <Redirect to='/dashboard/social' />
                  <Switch>
                    <PrivateRoute
                      path='/dashboard/social'
                      component={EditSocial}
                    />
                    <PrivateRoute
                      path='/dashboard/custom-links'
                      component={EditCustomLinks}
                    />
                    <PrivateRoute
                      path='/dashboard/appearance'
                      component={EditAppearance}
                    />
                    <PrivateRoute
                      path='/dashboard/settings'
                      component={EditSettings}
                    />
                    <PrivateRoute
                      path='/dashboard/support'
                      component={SupportPage}
                    />
                    {/* <EditSocial /> */}
                  </Switch>
                </div>

                <QrCodeUser isHidden={isHidden} />

                <div className='disp-title center-items'>
                  <p className='title-link'>
                    <p>Link: </p>
                    <a href={block_url} target='_blank'>
                      <p className='block-url'>{block_url}</p>
                    </a>
                  </p>

                  <div className='url-btn center-items'>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      onClick={() => setIsHidden(!isHidden)}
                      // startIcon={<ExitToAppIcon />}
                      className={classes.button}
                      disableElevation
                    >
                      QR Code
                    </Button>
                  </div>
                </div>
                <div className='app-disp' id='app-disp-cont'>
                  <div className='mobile-disp-container center-items'>
                    <div className='mobile-disp'></div>
                    <iframe
                      src={block_url}
                      id='mobile-disp-iframe'
                      style={{ border: 'none' }}
                      allowfullscreen
                      frameborder='0'
                    ></iframe>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(Dashboard);
