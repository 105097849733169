import React, { useState, useEffect, Fragment, useForm } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile, getCurrentProfile } from '../../actions/profile';
import CreateProfile from './CreateProfile';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#0167ff',
    // margin: '1em',
    // padding: '0.5em 1em',
    margin: '1em',
    padding: '0.5em 1em',
    '&:hover': {
      backgroundColor: '#0057da',
    },
    '&:active': {
      backgroundColor: '#0057da',
    },
  },
}));

const EditSocial = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  history,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    email: '',
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: '',
    linkedin: '',
    whatsapp: '',
    mobileNo: '',
    twitch: '',
    tiktok: '',
    discord: '',
    snapchat: '',
  });

  useEffect(() => {
    getCurrentProfile();
    setFormData({
      email: loading || !profile.socialLinks ? '' : profile.socialLinks.email,
      facebook:
        loading || !profile.socialLinks ? '' : profile.socialLinks.facebook,
      twitter:
        loading || !profile.socialLinks ? '' : profile.socialLinks.twitter,
      instagram:
        loading || !profile.socialLinks ? '' : profile.socialLinks.instagram,
      youtube:
        loading || !profile.socialLinks ? '' : profile.socialLinks.youtube,
      linkedin:
        loading || !profile.socialLinks ? '' : profile.socialLinks.linkedin,
      whatsapp:
        loading || !profile.socialLinks ? '' : profile.socialLinks.whatsapp,
      mobileNo:
        loading || !profile.socialLinks ? '' : profile.socialLinks.mobileNo,
      twitch: loading || !profile.socialLinks ? '' : profile.socialLinks.twitch,
      tiktok: loading || !profile.socialLinks ? '' : profile.socialLinks.tiktok,
      discord:
        loading || !profile.socialLinks ? '' : profile.socialLinks.discord,
      snapchat:
        loading || !profile.socialLinks ? '' : profile.socialLinks.snapchat,
    });
  }, [loading]);

  const {
    email,
    facebook,
    twitter,
    instagram,
    youtube,
    linkedin,
    whatsapp,
    mobileNo,
    twitch,
    tiktok,
    discord,
    snapchat,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = (e) => {
    e.preventDefault();
    createProfile(formData, history, true);
  };

  return (
    <React.Fragment>
      <form onSubmit={(e) => onSubmit(e)} className='app-form'>
        <div className='title center-items'>
          <span> Social Links </span>
          <div className='save-btn center-items'>
            <Button
              variant='contained'
              type='submit'
              color='primary'
              size='small'
              startIcon={<SaveIcon />}
              className={classes.button}
              disableElevation
            >
              Save
            </Button>
          </div>
        </div>
        <div className='data-all'>
          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='entypo-social:facebook'
                data-inline='false'
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='facebook'
                value={facebook}
                // placeholder='https://www.facebook.com/username/'
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='Facebook URL'
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <p className='input-adornment'>
                        https://www.facebook.com/
                      </p>
                    </InputAdornment>
                  ),
                }}
                // error
                // helperText='Some validation error '
                fullWidth
              />
            </span>
          </div>

          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='akar-icons:instagram-fill'
                data-inline='false'
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='instagram'
                value={instagram}
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='Instagram username'
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <p className='input-adornment'>@</p>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </span>
          </div>

          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='radix-icons:twitter-logo'
                data-inline='false'
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='twitter'
                value={twitter}
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='Twitter handle'
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <p className='input-adornment'>@</p>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </span>
          </div>
          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='clarity:email-line'
                data-inline='false'
                value
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='email'
                value={email}
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='Email'
                variant='outlined'
                size='small'
                fullWidth
              />
            </span>
          </div>
          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='bi:whatsapp'
                data-inline='false'
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='whatsapp'
                value={whatsapp}
                placeholder='+000000000000'
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='Whatsapp'
                variant='outlined'
                size='small'
                fullWidth
              />
            </span>
          </div>
          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='mdi:snapchat'
                data-inline='false'
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='snapchat'
                value={snapchat}
                // placeholder='https://www.snapchat.com/add/'
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='Snapchat'
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <p className='input-adornment'>
                        https://www.snapchat.com/add/
                      </p>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </span>
          </div>
          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='ls:linkedin'
                data-inline='false'
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='linkedin'
                value={linkedin}
                // placeholder='https://www.linkedin.com/in/'
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='LinkedIn'
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <p className='input-adornment'>
                        https://www.linkedin.com/in/
                      </p>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </span>
          </div>
          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='cib:tiktok'
                data-inline='false'
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='tiktok'
                value={tiktok}
                // placeholder='@tiktokUsername'
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='Tiktok'
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <p className='input-adornment'>@</p>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </span>
          </div>
          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='bi:youtube'
                data-inline='false'
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='youtube'
                value={youtube}
                // placeholder='https://www.youtube.com/channel/'
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='Youtube'
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <p className='input-adornment'>
                        https://www.youtube.com/channel/
                      </p>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </span>
          </div>
          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='dashicons:twitch'
                data-inline='false'
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='twitch'
                value={twitch}
                // placeholder='https://www.twitch.tv/'
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='Twitch'
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <p className='input-adornment'>https://www.twitch.tv/</p>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </span>
          </div>
          <div className='single-social-link center-items'>
            <span className='icon center-items'>
              <span
                className='iconify'
                data-icon='simple-icons:discord'
                data-inline='false'
              ></span>
            </span>
            <span className='input-link'>
              <TextField
                name='discord'
                value={discord}
                // placeholder='https://discord.gg/'
                onChange={(e) => onChange(e)}
                id='outlined-basic'
                label='Discord Server'
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <p className='input-adornment'>https://discord.gg/</p>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </span>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
EditSocial.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  withRouter(EditSocial)
);
